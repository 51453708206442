import React from "react";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/media-image.module.css";

const Image = (props) => {

  const {
    image
  } = props;

  return (
    <div className={styles.root} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      <Container>
        {image && image.asset && (
          <figure>
            <img
              src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
              alt={image.alt}
            />
            {image.caption && <figcaption>{image.caption}</figcaption>}
          </figure>
        )}
      </Container>
    </div>
  );
}

export default Image;
