import React from "react";
import Container from "./container";
import PostItem from "./post-item";

import * as styles from "../styles/components/media-posts.module.css";

const MediaPosts = (props) => {

  const {
    heading,
    posts
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        {posts && (
          <div className={styles.mediaPostList}>
            {posts.map((post) => {
              return (
                <PostItem {...post} />
              );
            })}
          </div>
        )}
      </Container>
    </div>
  );
}

export default MediaPosts;
