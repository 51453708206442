import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PageHeader from "../components/page-header";
import PressReleases from "../components/media-press-releases";
import Image from "../components/media-image";
import MediaPosts from "../components/media-posts";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query MediaPageQuery {
    media: sanityMedia {
      title
      pressReleasesHeading
      featuredImage {
        ...SanityImage
        alt
        caption
      }
      mediaPosts {
        heading
        posts {
          _key
          media
          link
          publishedOn
          title
        }
      }
      description
    }
  }
`;

const MediaPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const media = (data || {}).media;

  return (
    <Layout>
      <Seo
        title={media.title}
        description={media.description}
      />

      {media.title && <PageHeader title={media.title} />}

      <PressReleases heading={media.pressReleasesHeading} />

      {media.featuredImage && <Image image={media.featuredImage} />}

      {media.mediaPosts && <MediaPosts {...media.mediaPosts} />}
        
    </Layout>
  );
};

export default MediaPage;
