import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Container from "./container";
import PostItem from "./post-item";

import * as styles from "../styles/components/media-press-releases.module.css";

export const query = graphql`
  query PressReleasesQuery {
    pressReleases: allSanityPressRelease(
      sort: { fields: [publishedOn], order: DESC }
    ) {
      edges {
        node {
          _key
          title
          link
          file {
            asset {
              url
              originalFilename
            }
          }
          publishedOn
        }
      }
    }
  }
`;

const PressReleases = (props) => {

  const {
    heading
  } = props;

  return (
    <StaticQuery
    query={query}
    render={data => (
      <div className={styles.root}>
        <Container>
          {heading && <h2 className={styles.heading}>{heading}</h2>}
          {data && (
            <div className={styles.pressReleaseList}>
              {data.pressReleases.edges.map(({node: pressRelease}) => (
                <PostItem {...pressRelease} />
              ))}
            </div>
          )}
        </Container>
      </div>
    )} />
  );
}

export default PressReleases;
